/* eslint-disable prettier/prettier */
import * as React from 'react';
import { graphql, Link } from 'gatsby';
import { Button } from 'antd';

import Layout from '../components/layout';
import { ContentfulSectionConnection } from '../../types/graphql-types' // eslint-disable-line import/no-unresolved

interface Props {
  data: {
    allContentfulSection: ContentfulSectionConnection;
  };
}

const IndexPage: React.FC<Props> = (props: Props) =>  {
  const { data } = props;
  const { allContentfulSection } = data;
  const sections = allContentfulSection.edges.map(edge => edge.node);
  return (
    <Layout sections={sections}>
      <h2>Welcome to the PhotoDay Guide!</h2>
      <div>
        <p>Here you can find all the resources to help you get started with PhotoDay.</p>
        <Button type="primary"><Link to="/photoday">Get Started</Link></Button>
      </div>
    </Layout>
  );
};

export const query = graphql`
    query ContentfulSectionsQuery {
    allContentfulSection(sort: {fields: [sortOrder], order: ASC}) {
      edges {
        node {
            id
            title
            slug
        }
      }
    }
  }
`

export default IndexPage;
